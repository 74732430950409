import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Header from "../components/header"
import TitleSection from "./homepage/title-section"
import AwardsSection from "./homepage/awards-section"
import ProjectsSection from "./homepage/projects-section"
import CompanySection from "./homepage/company-section"
import StaffSection from "./homepage/staff-section"
import ContactUsSection from "./homepage/contact-us-section"

const IndexPage = () => (
  <Layout>
    <SEO />
    <TitleSection />
    <Header />
    <ProjectsSection />
    <CompanySection />
    <StaffSection />
    <AwardsSection />
    <ContactUsSection />
  </Layout>
)

export default IndexPage
