import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import PageSection from "../../components/page-section"
import SectionTitle from "../../components/section-title"
import { graphql, Link, useStaticQuery } from "gatsby"
import Img, { FluidObject } from "gatsby-image"
import Card from "react-bootstrap/Card"
import CardColumns from "react-bootstrap/CardColumns"

import "./projects-section.scss"

export type Project = {
  title: string
  project: string
  image: string
  description: string[]
  testimonial: string[]
}

type ProjectsSectionDataItem = {
  node: Project
}

export type ImageItem = {
  node: {
    name: string
    dir: string
    childImageSharp: { fluid: FluidObject }
  }
}

type ProjectsSectionData = {
  bannerImage: { childImageSharp: { fluid: FluidObject } }
  data: { edges: ProjectsSectionDataItem[] }
  images: { edges: ImageItem[] }
}

const ProjectsSection = () => {
  const projectsSectionData = useStaticQuery(graphql`
    query {
      bannerImage: file(relativePath: { eq: "projects-section-banner.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      data: allProjectsYaml {
        edges {
          node {
            title
            image
            project
          }
        }
      }
      images: allFile(filter: { sourceInstanceName: { eq: "images" } }) {
        edges {
          node {
            name
            dir
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const { bannerImage, data, images }: ProjectsSectionData = projectsSectionData

  const projects = data.edges.map((edge: ProjectsSectionDataItem) => {
    const { title, project, image } = edge.node

    const imagePathRegex = new RegExp(`images/projects/${project}`)

    const imageIndex = images.edges.findIndex(
      (x: ImageItem) =>
        x.node.name ===
          image
            .split(".")
            .slice(0, -1)
            .join(".") && x.node.dir.match(imagePathRegex)
    )

    return (
      <Link to={`/projects/${project}`} key={project}>
        <Card className="bg-dark text-white" key={project}>
          <div className="image-container">
            <Img
              fluid={images.edges[imageIndex].node.childImageSharp.fluid}
              className="card-img"
            />
            <div className="image-overlay" />
            <div className="image-title">{title}</div>
          </div>
        </Card>
      </Link>
    )
  })

  const renderProjects = () => {
    return (
      <Row>
        <Col>
          <CardColumns>{projects}</CardColumns>
        </Col>
      </Row>
    )
  }

  return (
    <PageSection
      label="projects"
      fluid={bannerImage.childImageSharp.fluid}
      bannerOverlay={"Interior Design and Project Management"}
    >
      <Container>
        <Row>
          <Col>
            <SectionTitle>Projects</SectionTitle>
          </Col>
        </Row>
        {renderProjects()}
      </Container>
    </PageSection>
  )
}

export default ProjectsSection
