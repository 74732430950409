import React from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Img from "gatsby-image"
import SectionTitle from "./section-title"
import LindseyProfileImage from "./lindsey-profile-image"
import HelenProfileImage from "./helen-profile-image"
import RachelProfileImage from "./rachel-profile-image"
import NeilProfileImage from "./neil-profile-image"
import { graphql, useStaticQuery } from "gatsby"

import "./staff-profile.scss"

type StaffMemberImageFilename = "lindsey.jpg" | "helen.jpg" | "rachel.jpg" | "neil.jpg"

export type StaffMember = {
  name: string
  biid: boolean
  image: {
    filename: StaffMemberImageFilename
    position: "left" | "right"
  }
  content: string[]
}

interface Props {
  staffMember: StaffMember
}

const StaffProfile = ({
  staffMember: { name, biid, image, content },
}: Props) => {
  const imageData = useStaticQuery(graphql`
    query {
      biidLogo: file(relativePath: { eq: "biid.png" }) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const renderContent = () =>
    content.map((paragraph, index) => (
      <p key={`staff-member-content-${index}`} className="text-justify">{paragraph}</p>
    ))

  const selectProfileImage = (filename: StaffMemberImageFilename) => {
    switch (filename) {
      case "lindsey.jpg":
        return LindseyProfileImage()
      case "helen.jpg":
        return HelenProfileImage()
      case "rachel.jpg":
        return RachelProfileImage()
      case "neil.jpg":
        return NeilProfileImage()
    }
  }

  const renderBiidLogo = () => (
    <Row>
      <Col xs={6}>
        <Img
          fluid={imageData.biidLogo.childImageSharp.fluid}
          className="biid-logo"
        />
      </Col>
    </Row>
  )

  return (
    <>
      <Row>
        <Col>
          <SectionTitle>{name}</SectionTitle>
        </Col>
      </Row>
      <Row className="team-member">
        <Col
          xs={{ span: 12 }}
          md={{ span: 5, order: image.position === "left" ? 1 : 3 }}
          lg={{ span: 5 }}
          xl={{ span: 6 }}
          className="mb-md-0 mb-4"
        >
          {selectProfileImage(image.filename)}
          {biid && renderBiidLogo()}
        </Col>
        <Col xs={{ order: 2 }}>{renderContent()}</Col>
      </Row>
    </>
  )
}

export default StaffProfile
