import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ProfileImage from "./profile-image"

const RachelProfileImage = () => {
  const data = useStaticQuery(graphql`
      query {
          profileImage: file(relativePath: { eq: "rachel.jpg"}) {
              childImageSharp {
                  fluid(maxWidth: 600) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
      }
  `)

  return <ProfileImage fluid={data.profileImage.childImageSharp.fluid} />
}

export default RachelProfileImage
