import React from "react"
import PageSection from "../../components/page-section"
import { graphql, useStaticQuery } from "gatsby"
import StaffProfile, { StaffMember } from "../../components/staff-profile"
import { FluidObject } from "gatsby-image"
import Container from "react-bootstrap/Container"

const StaffSection = () => {
  const pageData: {
    placeholderImage: { childImageSharp: { fluid: FluidObject } }
    staff: { edges: { node: StaffMember }[] }
  } = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "staff-section-banner.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      staff: allStaffYaml {
        edges {
          node {
            name
            biid
            image {
              filename
              position
            }
            content
          }
        }
      }
    }
  `)

  type StaffEdge = {
    node: StaffMember
  }

  const renderStaffProfiles = () =>
    pageData.staff.edges.map(
      ({ node: staffMember }: StaffEdge, index: number) => (
        <StaffProfile staffMember={staffMember} key={`staff-member-${index}`} />
      )
    )

  return (
    <PageSection
      label={`team`}
      fluid={pageData.placeholderImage.childImageSharp.fluid}
      bannerOverlay={"Inspirational Design"}
    >
      <Container>{renderStaffProfiles()}</Container>
    </PageSection>
  )
}

export default StaffSection
