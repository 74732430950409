import React from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Img, { FluidObject } from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

import "./awards-section.scss"
import PageSection from "../../components/page-section"
import CardColumns from "react-bootstrap/CardColumns"
import Card from "react-bootstrap/Card"
import Container from "react-bootstrap/Container"

const AwardsSection = () => {
  const imageData = useStaticQuery(graphql`
    query {
      awardImages: allFile(
        filter: {
          sourceInstanceName: { eq: "images" }
          relativeDirectory: { eq: "awards" }
        }
        sort: { fields: name }
      ) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      placeholderImage: file(
        relativePath: { eq: "awards-section-banner.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      designArchitecture2019: file(
        relativePath: {
          eq: "awards/DESIGN_ARCHITECTURE_2019_Winner_BLACK_400.png"
        }
      ) {
        childImageSharp {
          fluid(maxWidth: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const awards = imageData.awardImages.edges.map(
    (
      {
        node: {
          childImageSharp: { fluid },
        },
      }: { node: { childImageSharp: { fluid: FluidObject } } },
      index: number
    ) => (
      <Card key={index}>
        <Img fluid={fluid} />
      </Card>
    )
  )

  return (
    <PageSection
      label={`awards`}
      fluid={imageData.placeholderImage.childImageSharp.fluid}
      bannerOverlay={"An Award Winning Team"}
      reducedHeight
      noParallax
    >
      <Container className="mt-5">
        <Row>
          <Col>
            <CardColumns className="award-columns">{awards}</CardColumns>
          </Col>
        </Row>
      </Container>
    </PageSection>
  )
}

export default AwardsSection
