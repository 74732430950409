import React from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import PageSection from "../../components/page-section"
import { graphql, useStaticQuery } from "gatsby"
import Container from "react-bootstrap/Container"
import SectionTitle from "../../components/section-title"

const CompanySection = () => {
  const bannerImgData = useStaticQuery(graphql`
    query {
      placeholderImage: file(
        relativePath: { eq: "company-section-banner.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <PageSection
      label={`company`}
      fluid={bannerImgData.placeholderImage.childImageSharp.fluid}
      bannerOverlay={"Turnkey Solutions"}
    >
      <Container>
        <Row>
          <Col>
            <SectionTitle>About the Company</SectionTitle>
            <p className="text-justify">
              Rendall & Wright offer a comprehensive, bespoke interior design,
              project management and soft furnishing service.
            </p>

            <p className="text-justify">
              Launched by Lindsey Rendall and Helen Wright in 2006, this Suffolk
              based interior design practice has attracted a wide clientele base
              across the UK. Rendall & Wright have completed residential
              projects both new builds and period restorations along with
              commercial projects, including hotel, spa and restaurant design.
              Each project brings together a passion for functional design and
              attention to detail, coupled with seamless project management
              ensuring all clients feel confident about their project and leaves
              them free to enjoy the process of building, design and
              development.
            </p>

            <p className="text-justify">
              Rendall & Wright's design studio in Layham, Suffolk offers their
              clients a calm, peaceful environment to discuss their projects,
              contemplate decisions and source soft furnishings.
            </p>

            <p className="text-justify">
              Rendall & Wright have won numerous regional and international
              design awards. Their work has been featured in 25 Beautiful Homes,
              Ideal Home Magazine, Interior Design Yearbook, Interior Design
              Today, Beautiful Kitchens, Real Homes, Design et al, The Style
              Bible, Suffolk Magazine and Essential Magazine. Lindsey has
              contributed on numerous occasions to the 'ask the designer'
              section for Interior Design Today magazine and given talks at many
              events including The Homes and Gardens Show.
            </p>
          </Col>
        </Row>
      </Container>
    </PageSection>
  )
}

export default CompanySection
