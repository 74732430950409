import React, { useState } from "react"
import PageSection from "../../components/page-section"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { graphql, useStaticQuery } from "gatsby"
import SectionTitle from "../../components/section-title"
import "./contact-us.scss"
import { ParallaxBanner } from "react-scroll-parallax/cjs"

const ContactUsSection = () => {
  const [status, setStatus] = useState("")

  const bannerImgData = useStaticQuery(graphql`
    query {
      placeholderImage: file(
        relativePath: { eq: "contact-us-section-banner.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const submitForm = (event: any) => {
    event.preventDefault()

    const form = event.target
    const data = new FormData(form)
    const xhr = new XMLHttpRequest()
    xhr.open(form.method, form.action)
    xhr.setRequestHeader("Accept", "application/json")
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        form.reset()
        setStatus("SUCCESS")
      } else {
        setStatus("ERROR")
      }
    }
    xhr.send(data)
  }

  return (
    <PageSection
      //   fluid={bannerImgData.placeholderImage.childImageSharp.fluid}
      label={`contact-us`}
    >
      <ParallaxBanner
        style={{ height: "100vh" }}
        layers={[
          {
            image: bannerImgData.placeholderImage.childImageSharp.fluid.src,
            amount: 0.3,
            children: null,
          },
          {
            amount: 0,
            children: (
              <div className="contact-us-container">
                <div className="contact-us-inner">
                  <Container style={{ height: "100%" }}>
                    <Row style={{ height: "100%" }}>
                      <Col
                        style={{
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                        }}
                        xs={{ offset: 0, span: 12 }}
                        sm={{ offset: 2, span: 8 }}
                      >
                        <Container>
                          {status === "SUCCESS" ? (
                            <>
                              <Row>
                                <Col>
                                  <SectionTitle centered>
                                    Thank You!
                                  </SectionTitle>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <p className="text-center text-white">
                                    If you provided contact details we'll be in
                                    touch shortly.
                                  </p>
                                  <p className="text-center text-white">
                                    Kind Regards,
                                    <br />
                                    Rendall & Wright
                                  </p>
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <>
                              <Row>
                                <Col>
                                  <SectionTitle centered>
                                    Contact Us
                                  </SectionTitle>
                                </Col>
                              </Row>
                              <form
                                onSubmit={submitForm}
                                action="https://formspree.io/xpzpobrj"
                                method="POST"
                              >
                                <Row>
                                  <Col>
                                    <div className="input-container">
                                      <input
                                        className="input-field"
                                        type="text"
                                        name="name"
                                        placeholder="Your name"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="input-container">
                                      <input
                                        className="input-field"
                                        type="email"
                                        name="email"
                                        placeholder="Your email address"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="input-container">
                                      <input
                                        className="input-field"
                                        type="number"
                                        name="phone"
                                        placeholder="Your phone number"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <div className="input-container">
                                      <textarea
                                        name="message"
                                        cols={30}
                                        rows={10}
                                        placeholder="Your message"
                                        className="input-field"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col>
                                    <button
                                      className="btn btn-primary"
                                      style={{
                                        margin: "0 auto",
                                        display: "block",
                                      }}
                                    >
                                      Send Email
                                    </button>
                                    {status === "ERROR" && (
                                      <p>Ooops! There was an error.</p>
                                    )}
                                  </Col>
                                </Row>
                              </form>
                            </>
                          )}
                        </Container>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            ),
          },
        ]}
      />
    </PageSection>
  )
}

export default ContactUsSection
