import React from "react"
import "./page-sections.scss"

interface Props {
  children: string
  noTopPadding?: boolean
  centered?: boolean
}

const SectionTitle = ({ children, noTopPadding, centered }: Props) => {
  return (
    <h2 className={`section-title ${noTopPadding && "no-top-padding"} ${centered && "text-center"}`}>
      {children}
    </h2>
  )
}

export default SectionTitle
